<template>
    <section id="description-list-alignment">
        <b-row class="match-height">
            <b-col cols="12">
                <b-card>
                    <b-card-title>{{ $t("For Authors") }} </b-card-title>
                    
                    <app-collapse accordion type="margin">
                        <app-collapse-item title="f1q1">
                            <ul>
                                <li>
                                    {{ $t('f1a1') }}
                                </li>
                                <li>
                                    {{ $t('f1a2') }}
                                </li>
                                <li>
                                    {{ $t('f1a3') }}
                                </li>
                                <li>
                                    {{ $t('f1a4') }}
                                </li>
                                <li>
                                    {{ $t('f1a5') }}
                                </li>
                                <li>
                                    {{ $t('f1a6') }}
                                </li>
                                <li>
                                    {{ $t('f1a7') }}
                                </li>
                                <li>
                                    {{ $t('f1a8') }}
                                </li>
                            </ul>
                            
                        </app-collapse-item>
                        <app-collapse-item title="f2q2">
                            <ul>
                                <li>
                                    {{ $t('f2a1') }}
                                </li>
                                <li>
                                    {{ $t('f2a2') }}
                                </li>
                                <li>
                                    {{ $t('f2a3') }}
                                </li>
                                <li>
                                    {{ $t('f2a4') }}
                                </li>
                                <li>
                                    {{ $t('f2a5') }}
                                </li>
                                <li>
                                    {{ $t('f2a6') }}
                                </li>
                                <li>
                                    {{ $t('f2a7') }}
                                </li>
                                <li>
                                    {{ $t('f2a8') }}
                                </li>
                                <li>
                                    {{ $t('f2a9') }}
                                </li>
                                <li>
                                    {{ $t('f2a10') }}
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="f3q3">
                            <ul>
                                <li>
                                    {{ $t('f3a1') }}
                                </li>
                                <li>
                                    {{ $t('f3a2') }}
                                </li>
                                <li>
                                    {{ $t('f3a3') }}
                                </li>
                                <li>
                                    {{ $t('f3a4') }}
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="f4q4">
                            <ul>
                                <li>
                                    {{ $t('f4a1') }}
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="f5q5">
                            <ul>
                                <li>
                                    {{ $t('f5a1') }}
                                </li>
                                <li>
                                    {{ $t('f5a2') }}
                                </li>
                                <li>
                                    {{ $t('f5a3') }}
                                </li>
                                <li>
                                    {{ $t('f5a4') }}
                                </li>
                                <li>
                                    {{ $t('f5a5') }}
                                </li>
                                <li>
                                    {{ $t('f5a6') }}
                                </li>
                                <li>
                                    {{ $t('f5a7') }}
                                </li>
                                <li>
                                    <ul>
                                        <li>
                                            {{ $t('f5a8') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a9') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a10') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a11') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a12') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a13') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a14') }}
                                        </li>
                                        <li>
                                            {{ $t('f5a15') }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </app-collapse-item>
                        <app-collapse-item title="f6q6">
                            <ul>
                                <li>
                                    {{ $t('f6a1') }}
                                </li>
                                <li>
                                    {{ $t('f6a2') }}
                                </li>
                                <li>
                                    {{ $t('f6a3') }}
                                </li>
                                <li>
                                    {{ $t('f6a4') }}
                                </li>
                                <li>
                                    {{ $t('f6a5') }}
                                </li>
                                <li>
                                    {{ $t('f6a6') }}
                                </li>
                            </ul>
                        </app-collapse-item>
                    </app-collapse>
                </b-card>
            </b-col>
            <!--/ Description lists horizontal -->
        </b-row>
    </section>
</template>
  
<script>
import {
    BRow, BCol, BCard, BCardTitle, BCardText, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
    BFormRadioGroup, BFormRadio, BFormGroup,
} from 'bootstrap-vue'
import { codeCollapseTypes } from './code'
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardTitle,
        BCardText,
        BCardHeader, AppCollapseItem, AppCollapse, BCardCode,
        BCardBody, BFormRadioGroup, BFormRadio, BFormGroup
    },
    data() {
        return {
            collapseType: 'default',
            codeCollapseTypes,
        }
    },
}
</script>
  